import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { selectCounts } from './selectors'
import {
  ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts'
import ChangeoversCountsTooltip from './ChangeoversCountsTooltip'

const BAR_SIZE = 80

const messages = defineMessages({
  title: {
    id: 'oee.locationChangeoversCounts.title',
    defaultMessage: 'Changeovers'
  }
})

const ChangeoversCounts = ({ data, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ width: '100%', height: 450, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            syncId='locationChangeovers'
            width={450}
            height={450}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 20, left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='month'
              tickFormatter={tick => moment(tick).format('MMM YYYY')}
            />
            <YAxis />
            <Tooltip
              isAnimationActive={false}
              content={ChangeoversCountsTooltip}
            />
            <Bar
              dataKey='count'
              fill='#3072B5'
              barSize={BAR_SIZE}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

ChangeoversCounts.propTypes = {
  data: PropTypes.array.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectCounts(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ChangeoversCounts)
