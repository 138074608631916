import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const ChangeoversCountsTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>{moment(label).format('MMM YYYY')}</div>

        {payload[0] && payload[0].value}
      </div>
    )
  }

  return null
}

ChangeoversCountsTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number
  })),
  label: PropTypes.string.isRequired
}

export default ChangeoversCountsTooltip
