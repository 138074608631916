import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectLocation } from 'oee/locationDashboard/selectors'
import PageHeader from 'components/PageHeader'
import Navigation from 'containers/Navigation/Navigation'
import NavigationDateRangeInfo from 'containers/Navigation/NavigationDateRangeInfo'

const ReportHeader = ({ name }) => (
  <div className='report-header'>
    <PageHeader
      title={
        <React.Fragment>
          <h1>{name}</h1>
          <NavigationDateRangeInfo />
        </React.Fragment>
      }
      className='report-header__title'
    />

    <Navigation />
  </div>
)

ReportHeader.propTypes = {
  name: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  name: selectLocation(state).name
})

export default connect(mapStateToProps)(ReportHeader)
