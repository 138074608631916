import React from 'react'
import ChangeoversHeader from 'oee/locationChangeovers/ChangeoversHeader'
import ChangeoversDurations from 'oee/locationChangeovers/ChangeoversDurations'
import ChangeoversCounts from 'oee/locationChangeovers/ChangeoversCounts'

export default () => (
  <React.Fragment>
    <ChangeoversHeader />
    <ChangeoversDurations />
    <ChangeoversCounts />
  </React.Fragment>
)
