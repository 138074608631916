import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Duration from 'components/Duration'

const ChangeoversDurationsTooltipValue = ({ color, name, value }) => (
  <div className='recharts-custom-tooltip__value'>
    <div className='recharts-custom-tooltip__dot' style={{ backgroundColor: color }} />
    {name}: <Duration minutes={value * 60} />
  </div>
)

ChangeoversDurationsTooltipValue.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

const ChangeoversDurationsTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div className='recharts-custom-tooltip recharts-custom-tooltip--dots'>
        <div className='recharts-custom-tooltip__label'>{moment(label).format('MMM YYYY')}</div>

        {payload.map(bar => <ChangeoversDurationsTooltipValue key={bar.dataKey} {...bar} />)}
      </div>
    )
  }

  return null
}

ChangeoversDurationsTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number
  })),
  label: PropTypes.string.isRequired
}

export default ChangeoversDurationsTooltip
