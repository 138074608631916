import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { selectDurations } from './selectors'
import {
  ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip
} from 'recharts'
import ChangeoversDurationsTooltip from './ChangeoversDurationsTooltip'

const BAR_SIZE = 80

const messages = defineMessages({
  title: {
    id: 'oee.locationChangeoversDurations.title',
    defaultMessage: 'OEE Changeovers'
  },
  hoursLabel: {
    id: 'oee.locationChangeoversDurations.hoursLabel',
    defaultMessage: '{hours} h'
  },
  engineeringDuration: {
    id: 'oee.locationChangeoversDurations.engineeringDuration',
    defaultMessage: 'Engineering Duration'
  },
  setupDuration: {
    id: 'oee.locationChangeoversDurations.setupDuration',
    defaultMessage: 'Setup Duration'
  }
})

const ChangeoversDurations = ({ data, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ width: '100%', height: 450, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            syncId='locationChangeovers'
            width={450}
            height={450}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 20, left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='month'
              tickFormatter={tick => moment(tick).format('MMM YYYY')}
            />
            <YAxis
              tickFormatter={tick => intl.formatMessage(messages.hoursLabel, { hours: tick })}
            />
            <Legend />
            <Tooltip
              isAnimationActive={false}
              content={ChangeoversDurationsTooltip}
            />
            <Bar
              dataKey='engineeringHours'
              name={intl.formatMessage(messages.engineeringDuration)}
              fill='#00BB5E'
              stackId={1}
              barSize={BAR_SIZE}
            />
            <Bar
              dataKey='setupHours'
              name={intl.formatMessage(messages.setupDuration)}
              fill='#FFB1A1'
              stackId={1}
              barSize={BAR_SIZE}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

ChangeoversDurations.propTypes = {
  data: PropTypes.array.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectDurations(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ChangeoversDurations)
