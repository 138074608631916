import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { selectNavigationRange } from './selectors'

const NavigationDateRangeInfo = ({ from, to, format, className }) => {
  return (
    <span className={className}>
      <FormattedMessage id='containers.navigation.dateRangeInfo' defaultMessage='Displaying date range' />
      {': '}
      <strong>{from.format(format)} &ndash; {to.clone().subtract(1, 'day').format(format)}</strong>
    </span>
  )
}

NavigationDateRangeInfo.defaultProps = {
  format: 'MMMM YYYY'
}

NavigationDateRangeInfo.propTypes = {
  format: PropTypes.string.isRequired,
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  className: PropTypes.any
}

const mapStateToProps = state => selectNavigationRange

export default connect(mapStateToProps)(NavigationDateRangeInfo)
