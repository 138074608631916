import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import api from 'lib/api/client'
import { SET_NAVIGATION, configureNavigation, setNavigationDate } from 'containers/Navigation/actions'
import { selectNavigationRangeDates } from 'containers/Navigation/selectors'
import { TYPE_YEAR_TO_DATE } from 'containers/Navigation/const'
import { selectToken, selectLocation } from 'oee/locationChangeovers/selectors'
import {
  receiveLocation,
  requestChangeovers,
  receiveChangeovers
} from 'oee/locationChangeovers/actions'
import {
  finishInit,
  startInit
} from './actions'
import {
  selectTimezone
} from './selectors'

export function * init () {
  yield put(startInit())

  const timezone = yield select(selectTimezone)
  yield put(configureNavigation(TYPE_YEAR_TO_DATE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const token = yield select(selectToken)
  const id = window.location.pathname.split('/').slice(-2)[0]
  const location = yield call(api.get, `/locations/${id}`, { token })
  yield put(receiveLocation(location))

  yield call(changeovers)

  yield put(finishInit())
}

export function * changeovers () {
  yield put(requestChangeovers())
  const token = yield select(selectToken)
  const params = yield select(selectNavigationRangeDates)
  const { id } = yield select(selectLocation)
  const { durations, counts } = yield call(api.get, `/oee/dashboard/location/${id}/changeovers`, { params, token })

  yield put(receiveChangeovers({
    durations: durations.map(row => {
      return {
        ...row,
        setupHours: row.setupDuration ? row.setupDuration / 60 : null,
        engineeringHours: row.engineeringDuration ? row.engineeringDuration / 60 : null
      }
    }),
    counts
  }))
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'OEE Dashboard')
  }

  yield call(init)

  yield takeLatest(SET_NAVIGATION, changeovers)
}
