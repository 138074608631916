import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_LOCATION: 'RECEIVE_LOCATION',
  REQUEST_CHANGEOVERS: 'REQUEST_CHANGEOVERS',
  RECEIVE_CHANGEOVERS: 'RECEIVE_CHANGEOVERS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocation = createAction(Actions.RECEIVE_LOCATION)
export const requestChangeovers = createAction(Actions.REQUEST_CHANGEOVERS)
export const receiveChangeovers = createAction(Actions.RECEIVE_CHANGEOVERS)
