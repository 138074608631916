import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

function extractHours (minutes) {
  return Math.floor(minutes / 60)
}

function extractMinutes (minutes) {
  return minutes - extractHours(minutes) * 60
}

function formatHours (hours) {
  return hours > 0 ? hours : ''
}

function formatMinutes (minutes) {
  return Math.round(minutes)
}

const Duration = ({ minutes }) => {
  const hours = extractHours(minutes)

  return (
    <React.Fragment>
      {
        hours > 0 &&
        <FormattedMessage
          id='components.duration.hours'
          defaultMessage='{hours} h'
          values={{ hours: formatHours(hours) }}
        />
      }
      &nbsp;
      <FormattedMessage
        id='components.duration.minutes'
        defaultMessage='{minutes} min'
        values={{ minutes: formatMinutes(extractMinutes(minutes)) }}
      />
    </React.Fragment>
  )
}

Duration.propTypes = {
  minutes: PropTypes.number.isRequired
}

export default Duration
