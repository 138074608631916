import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'oee/locationChangeovers/actions'
import navigation from 'containers/Navigation/reducer'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const location = handleActions(
  {
    [Actions.RECEIVE_LOCATION]: (state, { payload: location }) => location
  },
  { name: '' }
)

const changeovers = handleActions(
  {
    [Actions.RECEIVE_CHANGEOVERS]: (state, { payload: data }) => data
  },
  { durations: [], counts: [] }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const loading = handleActions(
  {
    [Actions.REQUEST_TIMELINE]: () => true,
    [Actions.RECEIVE_TIMELINE]: () => false
  },
  false
)

export default combineReducers({
  config,
  location,
  init,
  navigation,
  loading,
  changeovers
})
